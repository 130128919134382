'use client';

import { config } from '@client/src/config';
import { usePathname, useRouter } from '@client/src/navigation';
import { AvailableLocales } from '@client/src/routes/types';
import { useLocale, useTranslations } from 'next-intl';
import { Select } from '../select/select';

const localesToShow = config.i18n.locales.filter(
  (locale) => locale === 'pl' || locale === 'en',
);

export function LanguageSelector({ className }: { className?: string }) {
  const locale = useLocale() as AvailableLocales[number];
  const t = useTranslations('components.language-selector');
  const router = useRouter();
  const pathname = usePathname();

  const handleValueChange = (newValue: string) =>
    router.push(pathname, { locale: newValue });

  return (
    <Select
      className={className}
      triggerPlaceholder={locale}
      ariaDescription="Language"
      currentValue={locale}
      onValueChange={handleValueChange}
      items={localesToShow.map((locale) => ({
        value: locale,
        label: t(locale),
      }))}
    />
  );
}
