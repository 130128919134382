import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/1.Security/1.Security/apps/client/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/home/runner/work/1.Security/1.Security/apps/client/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/1.Security/1.Security/apps/client/src/auth/context/next-auth/auth-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageSelector"] */ "/home/runner/work/1.Security/1.Security/apps/client/src/components/language-selector/language-selector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/1.Security/1.Security/apps/client/src/data-access/react-query/react-query-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderNav"] */ "/home/runner/work/1.Security/1.Security/apps/client/src/layouts/main/header-nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MainFooter"] */ "/home/runner/work/1.Security/1.Security/apps/client/src/layouts/main/main-footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleFooter"] */ "/home/runner/work/1.Security/1.Security/apps/client/src/layouts/main/simpleFooter.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/1.Security/1.Security/apps/client/src/theme/globalStyles.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/1.Security/1.Security/apps/client/src/theme/reset.global.css");
