'use client';

import { routesManager } from '@client/src/routes/helpers/routesManager';
import { AvailableLocales } from '@client/src/routes/types';
// import { CTAModal } from '@client/src/sections/home/landing/cta-modal';
import { useLocale, useTranslations } from 'next-intl';
import Link from 'next/link';
import { useState } from 'react';

const navItems = [
  // { name: 'About us', href: '/about' },
  // { name: 'FAQs', href: '/faqs' },
  // { name: 'Signin', href: '/signin' },
] as const;

export function HeaderNav() {
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = () => setIsOpen(!isOpen);
  const locale = useLocale() as AvailableLocales[number];

  const { routes } = routesManager({ locale });
  const t = useTranslations('components.layouts/main/header');

  return (
    <>
      <button
        type="button"
        className="inline-block sm:hidden p-[8px] cursor-pointer bg-transparent border-0 m-0"
        onClick={handleChange}
      >
        <span className="w-[24px] h-[20px] inline-block relative">
          <span
            className={[
              'w-full h-[3px] bg-slate-900 absolute left-0 top-1/2 -translate-y-1/2 transition-all duration-200',
              "before:content-[''] before:left-0 before:w-full before:h-[3px] before:bg-slate-900 before:absolute before:top-[-8px] before:transition-all before:duration-200",
              "  after:content-['']  after:left-0 after:w-full after:h-[3px] after:bg-slate-900 after:absolute after:top-[8px] after:transition-all after:duration-200",
              isOpen ? '!bg-transparent' : '',
              isOpen ? 'before:translate-y-[8px] before:rotate-45' : '',
              isOpen ? 'after:-translate-y-[8px] after:-rotate-45' : '',
            ]
              .join(' ')
              .trim()}
          ></span>
        </span>
      </button>

      <ul
        className={[
          'flex text-center sm:text-left h-screen w-full bg-white fixed top-20 left-0 z-10 flex-col translate-x-full p-4 pt-8 sm:top-0 sm:p-0 sm:h-auto sm:w-auto sm:relative sm:translate-x-0 sm:flex sm:flex-row sm:space-between sm:items-center sm:justify-end gap-10 font-medium transition-transform duration-200',
          isOpen ? '!translate-x-0' : '',
        ]
          .join(' ')
          .trim()}
      >
        {navItems.map(({ name, href }) => (
          <li key={name}>
            <Link
              className="py-2 w-full block sm:inline sm:py-0 sm:w-auto text-gray-700 no-underline hover:text-gray-600"
              key={name}
              href={routes[href].href}
            >
              {t(name)}
            </Link>
          </li>
        ))}
        {/* <li>
          <CTAModal />
        </li> */}
      </ul>
    </>
  );
}
