'use client';

import { routesManager } from '@client/src/routes/helpers/routesManager';
import { AvailableLocales } from '@client/src/routes/types';
import { useLocale, useTranslations } from 'next-intl';
import { usePathname } from 'next/navigation';

export function SimpleFooter() {
  const pathname = usePathname();
  const locale = useLocale() as AvailableLocales[number];
  const { publicRoutes } = routesManager({ locale });
  const t = useTranslations('components.layouts/main/simpleFooter');

  return pathname === publicRoutes['/'].href ? null : (
    <footer className="py-5 text-center relative bg-background-default">
      <div className="container mx-auto">
        {/* Replace with your actual Logo component */}
        <div className="mb-1 mx-auto">{/* Logo */}</div>

        <div className="text-xs">
          © {new Date().getFullYear()}. {t('All rights reserved')}
          <br /> 1 Security
        </div>
      </div>
    </footer>
  );
}
