import { join } from 'path';

import { protectedCollection } from '../protected';
import { publicCollection } from '../public';
import type { AvailableLocales, Path } from '../types';
import { readExecutingFileFromErrorStack } from './readExecutingFileFromErrorStack';

type Props = {
  infer?: boolean;
  currentPath?: string | Headers;
  locale?: AvailableLocales[number];
};

export function routesManager(props?: Props) {
  if (props?.infer) {
    const executingFile = readExecutingFileFromErrorStack();
    const avaiableFiles = ['page.js', 'page.tsx'];
    const isProperFileType = avaiableFiles.some((file) =>
      executingFile.includes(file),
    );

    if (!isProperFileType)
      throw new Error(
        `You can only infer the path when you execute the 'routesManager' function inside: ${avaiableFiles.join(
          ', ',
        )}.`,
      );

    const appLocalePath = join('app', '[locale]');
    const inferedPath = executingFile
      .split(appLocalePath)[1]
      .replace(new RegExp(avaiableFiles.join('|')), '')
      .replace(/(\/|\\)\[([^/]+)\]/g, ':$1')
      .replace(/(\/|\\)\(.+\)/, '')
      .replaceAll(/\\/g, '/')
      .replace(/(?<=.)(\/)(?!.)/, '') as Path;

    protectedCollection.currentPath = inferedPath;
    publicCollection.currentPath = inferedPath;
  } else if (typeof props?.currentPath === 'string') {
    protectedCollection.currentPath = props.currentPath as Path;
    publicCollection.currentPath = props.currentPath as Path;
  } else if (props?.currentPath) {
    const headersPathname =
      (props.currentPath.get('x-invoke-path') as Path) ?? undefined;

    protectedCollection.currentPath = headersPathname;
    publicCollection.currentPath = headersPathname;
  }

  if (props?.locale) {
    protectedCollection.currentLocale = props.locale;
    publicCollection.currentLocale = props.locale;
  }

  const protectedRoutes = protectedCollection.routes;
  const publicRoutes = publicCollection.routes;
  const routes = { ...protectedRoutes, ...publicRoutes };

  const findRoute_async = async ({ href }: { href: Path }) => {
    const protectedCollectionSearchResult =
      await protectedCollection.findRoute_async({ href });
    const publicCollectionSearchResult = await publicCollection.findRoute_async(
      {
        href,
      },
    );

    return protectedCollectionSearchResult ?? publicCollectionSearchResult;
  };

  const findRoute_sync = ({ href }: { href: Path }) => {
    const protectedCollectionSearchResult = protectedCollection.findRoute_sync({
      href,
    });
    const publicCollectionSearchResult = publicCollection.findRoute_sync({
      href,
    });

    return protectedCollectionSearchResult ?? publicCollectionSearchResult;
  };

  const getCurrentRoute = async () =>
    protectedCollection.currentPath &&
    (await findRoute_async({ href: protectedCollection.currentPath })); // Public and protected have the same currentPath

  return {
    protectedRoutes,
    publicRoutes,
    routes,
    findRoute_async,
    findRoute_sync,
    getCurrentRoute,
  };
}
