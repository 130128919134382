'use client';

import Image from 'next/image';

import {
  RiFacebookBoxFill,
  RiInstagramLine,
  RiLinkedinBoxFill,
  RiTiktokLine,
  RiTwitterXLine,
} from 'react-icons/ri';
import { FaRegCircleCheck } from "react-icons/fa6";

import { routesManager } from '@client/src/routes/helpers/routesManager';
import { useLocale, useTranslations } from 'next-intl';

import { AvailableLocales } from '@client/src/routes/types';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import investorLogos from '/public/assets/landing/eu-logos.webp';

const LINKS = [
  // {
  //   headline: 'About',
  //   children: [
  //     { name: 'About us', href: '/about' },
  //     { name: 'FAQs', href: '/faqs' },
  //   ],
  // },
  {
    headline: 'Legal',
    children: [
      {
        name: 'Terms and Condition',
        href: '/legal/terms-and-conditions',
      },
      {
        name: 'Privacy Policy',
        href: '/legal/privacy-policy',
      },
      {
        name: 'Cookies',
        href: '/legal/cookies',
      },
    ],
  },
  {
    headline: 'Contact',
    children: [
      { name: 'support@1.security', href: 'mailto:support@1.security' },
    ],
  },
] as const;

const SOCIAL_MEDIA_LINKS = [
  {
    name: 'Facebook',
    icon: RiFacebookBoxFill,
    url: 'https://www.facebook.com/profile.php?id=61553001781259',
    size: '32px',
  },
  {
    name: 'LinkedIn',
    icon: RiLinkedinBoxFill,
    url: 'https://linkedin.com/company/1security',
    size: '32px',
  },
  {
    name: 'TikTok',
    icon: RiTiktokLine,
    url: 'https://www.tiktok.com/@user6518143850362',
    size: '28px',
  },
  {
    name: 'Instagram',
    icon: RiInstagramLine,
    url: 'https://www.instagram.com/1_security_/',
    size: '28px',
  },

  {
    name: 'Twitter',
    icon: RiTwitterXLine,
    url: 'https://twitter.com/1_security80231',
    size: '24px',
  },
];

export function MainFooter() {
  const pathname = usePathname();
  const locale = useLocale() as AvailableLocales[number];
  const { publicRoutes, routes } = routesManager({ locale });
  const t = useTranslations('components.layouts/main/mainFooter');

  return pathname !== publicRoutes['/'].href ? null : (
    <footer className="relative pt-16 bg-white">
      <div className="flex flex-col items-center container mx-auto pt-1 pb-1 text-center md:text-left">
        <div className="flex w-full col-span-1 md:col-span-3 justify-center">
          <div className="flex lg:max-w-1/2 pb-10 flex-col md:flex-row gap-20 md:gap-40">
            {LINKS.map((list) => (
              <div
                key={list.headline}
                className="flex flex-col space-y-7 justify-start items-start"
              >
                <div className="text-lg uppercase text-[#545454] text-left mt-0 font-bold">
                  {t(list.headline)}
                </div>

                {list.children.map((link) => (
                  <Link
                    key={link.name}
                    href={
                      link.name === 'support@1.security'
                        ? link.href
                        : routes[link.href].href
                    }
                    className="text-gray-700 hover:text-gray-500 text-center mt-2 font-normal no-underline hover:cursor-pointer "
                  >
                    {link.name === 'support@1.security'
                      ? link.name
                      : t(link.name)}
                  </Link>
                ))}
              </div>
            ))}
          </div>
        </div>

        {/* Bottom bar */}
        <div className="grid border-solid p-4 border-t border-[#545454]/[.35] lg:w-2/3 grid-flow-col sm:grid-flow-row grid-rows-3 sm:grid-cols-3 sm:grid-rows-1 col-span-1 sm:col-span-3 justify-start sm:justify-between items-center">
          {/* Investor logos */}
          <div className="flex justify-center items-center">
            <Image
              height={150}
              alt="1 Security app"
              src={investorLogos}
              className="w-auto md:mt-0 object-contain h-auto"
            />
          </div>
          {/* Copyrights */}
          <div className="text-xs mt-0 text-gray-700 text-center">
            © {new Date().getFullYear()}. {t('All rights reserved')}
          </div>
          {/* Social media links */}
          <div className="flex justify-center md:justify-end items-center gap-5">
            {SOCIAL_MEDIA_LINKS.map((link) => (
              <Link
                key={link.name}
                href={link.url}
                className="text-gray-700 hover:text-gray-600 hover:scale-110 flex justify-center items-center hover:cursor-pointer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <link.icon
                  className={'flex justify-center items-center'}
                  size={link.size}
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
}
