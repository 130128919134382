export function readExecutingFileFromErrorStack() {
  const { stack } = new Error();
  const stackLines = stack?.split('\n') || [];

  if (process.env.NODE_ENV === 'production') {
    // It depends on building environment
    const filePath = stackLines[3]?.split(' ') ?? stackLines[2].split(' ');

    return filePath[filePath.length - 1].replace(/[()]/, '').split(':')[0];
  }

  return __filename;
}
