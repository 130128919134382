'use client';

import { CheckIcon, ChevronDownIcon } from '@radix-ui/react-icons';
import {
  Content,
  Icon,
  Item,
  ItemIndicator,
  ItemText,
  Portal,
  Root,
  Trigger,
  Value,
  Viewport,
} from '@radix-ui/react-select';

import styles from './select.module.css';

interface SelectProps {
  triggerPlaceholder: string;
  ariaDescription: string;
  items: { value: string; label: string }[];
  onValueChange: (v: string) => void;
  currentValue: string;
  className?: string;
}

export function Select({
  triggerPlaceholder,
  ariaDescription,
  items,
  onValueChange,
  currentValue,
  className,
}: SelectProps) {
  return (
    <Root value={currentValue} onValueChange={onValueChange}>
      <Trigger
        className={[styles.SelectTrigger, className].join(' ').trim()}
        aria-label={ariaDescription}
      >
        <Value placeholder={triggerPlaceholder} />
        <Icon className="SelectIcon">
          <ChevronDownIcon />
        </Icon>
      </Trigger>
      <Portal>
        <Content
          className={styles.SelectContent}
          position="popper"
          sideOffset={5}
        >
          <Viewport className={styles.SelectViewport}>
            {items.map(({ value, label }) => (
              <SelectItem key={value} value={value}>
                {label}
              </SelectItem>
            ))}
          </Viewport>
        </Content>
      </Portal>
    </Root>
  );
}

interface SelectItemProps {
  children: React.ReactNode;
  className?: string;
  value: string;
}

const SelectItem = ({ children, className, ...props }: SelectItemProps) => {
  return (
    <Item className={[styles.SelectItem, className].join(' ')} {...props}>
      <ItemText>{children}</ItemText>
      <ItemIndicator className={styles.SelectItemIndicator}>
        <CheckIcon />
      </ItemIndicator>
    </Item>
  );
};
